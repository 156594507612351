























































































import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, FileUpload, EditPhonemesRequest, PhonemesInfo } from '@/edshed-common/api'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import FileInput from '@/edshed-common/components/FileInput.vue'
import { getFilesBucketName } from '@/utils/helpers'

@Component({
  name: 'EditPhoneme',
  components: {
    FileInput
  }
})

export default class EditPhoneme extends Mixins(ComponentHelper) {
  @Prop({ required: true }) phoneme!: PhonemesInfo

  loading: boolean = false
  code: string = this.phoneme.code
  name: string = this.phoneme.name
  ipa: string | null = this.phoneme.ipa
  record: { new_file?: FileUpload } = {}
  formErrors: string[] = []
  showErrorDiv = false
  removedAudio = false

  get mp3File () {
    if (this.removedAudio) {
      return ''
    } else {
      return 'https://' + getFilesBucketName() + '/audio/dictionary/' + this.userLocale + '/PHONICS/mp3/' + this.phoneme.code.toLowerCase() + '.mp3'
    }
  }

  get oggFile () {
    if (this.removedAudio) {
      return ''
    } else {
      return 'https://' + getFilesBucketName() + '/audio/dictionary/' + this.userLocale + '/PHONICS/ogg/' + this.phoneme.code.toLowerCase() + '.ogg'
    }
  }

  onPlay (event) {
    if (this.removedAudio) {
      event.preventDefault()
      event.stopPropagation()
      event.target.pause()
    }
  }

  handleFileUpload (fileData: FileUpload) {
    this.record = { new_file: fileData }
  }

  async handleSubmit () {
    this.loading = true
    try {
      this.showErrorDiv = false
      this.formErrors = []
      if (this.record.new_file && this.record.new_file.extname && this.record.new_file.size) {
        if (this.record.new_file.size > 500000) {
          this.formErrors.push('The file size exceeds the limit allowed')
        }
        if (!/(.mp3|.ogg|.wav)/.test(this.record.new_file.extname)) {
          this.formErrors.push('Wrong file type')
        }
      }
      if (!this.code) {
        this.formErrors.push('The code field is required')
      }
      if (!this.name) {
        this.formErrors.push('The name field is required')
      }
      if ((this.code !== '_' || this.name !== 'no sound') && !this.ipa) {
        this.formErrors.push('The ipa field is required')
      }
      if (this.formErrors.length > 0) {
        this.showErrorDiv = true
        return
      }

      let new_file: FileUpload | undefined | null
      if (this.record.new_file && this.record.new_file.data && this.record.new_file.extname && this.record.new_file.size) {
        new_file = this.record.new_file
      }

      if (this.removedAudio) {
        new_file = null
      }

      const phoneme: EditPhonemesRequest = {
        code: this.code,
        name: this.name,
        ipa: this.ipa,
        file: new_file
      }

      const res = await Api.editPhoneme(this.phoneme.id, phoneme)
      this.$emit('edited-phoneme', res)
    } catch (err: unknown) {
      this.$emit('error', err, 'edit')
    } finally {
      this.loading = false
    }
  }
}
